import appConfig from "@/config/app.config";
const { layouts } = appConfig;

/**
 * Routes meta
 */
const meta = {
  layout: layouts.app,
  requiresAuth: true,
};

/**
 * App routes
 */
const appRoutes = [
  {
    path: '',
    redirect: { name: 'profile' }
  },
  {
    path: '/dashboard',
    redirect: { name: 'profile' }
  },
  {
    path: "/profile",
    name: "profile",
    meta,
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "@/views/Dashboard/Dashboard.vue"
      ),
  },
  {
    path: "/preferences",
    name: "ProfilePrefferences",
    meta,
    component: () =>
      import( 
        /* webpackChunkName: "profile-prefferences" */ "@/components/widgets/ProfilePrefferences.vue"
      ),
  },
  
];

export default Object.freeze(appRoutes);
