import appConfig from "@/config/app.config";
const { layouts } = appConfig;

/**
 * Routes meta
 */
const meta = {
  layout: layouts.auth,
  requiresAuth: false,
};

/**
 * Auth routes
 */
const authRoutes = [
//   {
//     path: "/galaxy-login",
//     name: "galaxy_login",
//     meta,
//     component: () =>
//       import(
//         /* webpackChunkName: "galaxy-login" */ "@/views/Auth/GalaxyLogin.vue"
//       ),
//   },
  {
    path: "/account-selector",
    name: "account-selector",
    meta: { ...meta, requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "account-selector" */ "@/views/IntegrationsAccountSelector.vue"
      ),
  },
];

export default authRoutes;
