<template>
  <div class="layout-wrapper">
    <component :is="layout" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AppLayout from "@/components/layouts/AppLayout";
import AuthLayout from "@/components/layouts/AuthLayout";

/**
 * Main vue root file of the application
 */
export default {
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    AppLayout,
    AuthLayout,
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    /**
     * Fetches current route's layout
     */
    layout() {
      const { meta } = this.$route;
      if (!meta?.layout) return "";

      const { auth, app } = this.$appConfig.layouts;
      return `${this.isLoggedIn() || this.$keycloak.authenticated ? meta.layout ?? app : auth}`;
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      setKeycloakCurrentUserDetails: "auth/setKeycloakCurrentUserDetails",
    }),
    /**
     * Checks whether user is logged in or not from the localstorage persisted data
     * @returns {Boolean} Is logged in or not
     */
    isLoggedIn() {
      return (
        JSON.parse(localStorage.getItem("mp-id"))?.auth
          ?.isLoggedIn ?? false
      );
    },
  },
  async created() {
    try {
      // Check if the user is authenticated in Keycloak but is not considered logged in according to the application's logic.
      // This ensures synchronization between Keycloak authentication state and the application's login state.
      if ((this.$keycloak && this.$keycloak.authenticated) && !this.isLoggedIn()) {
        const profile = await this.$keycloak.loadUserProfile();
        profile.phoneNumber = profile?.attributes?.phoneNumber?.[0]
        profile.companyName = profile?.attributes?.companyName?.[0]
        profile.token = this.$keycloak.token
        await this.setKeycloakCurrentUserDetails(profile)
      }
    } finally {
      document.getElementById("splash").style.display = "none";
    }
  },
};
</script>
