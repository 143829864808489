/**
 * Application configuration
 * @type {Object}
 */
const appConfig = {
  /**
   * Application layouts
   */
  layouts: {
    app: "AppLayout",
    auth: "AuthLayout",
  },
  /** Tooltip */
  tooltip: {
    type: {
      default: "default",
      dark: "dark",
      success:'success'
    },
    colors: {
      default: "",
      dark: "rgba(0, 0, 0, 0.87)",
      success: "rgb(188, 236, 200)"
    },
  },
  // Alert
  alert: {
    success: {
      color: "#BCECC8",
      iconColor: "#29993E",
      icon: "mdi-check-circle-outline",
    },
    error: {
      color: "rgba(239, 197, 202, 1)",
      iconColor: "rgba(176, 0, 32, 1)",
      icon: "mdi-alert-circle-outline",
    },
    info: {
      color: "#F2F2F2",
      iconColor: "rgba(0, 0, 0, 0.87)",
      icon: "mdi-alert-circle-outline",
    },
    // Alert types
    types: {
      info: "info",
      error: "error",
      success: "success",
    },
  },
  // Snackbar configuration
  snackbar: {
    timeout: 10000,
    successBgColor: "primary",
    infoBgColor: "#f7d36f",
    errorBgColor: "#ff3232",

    // Snackbar configuration
    config: {
      success: {
        icon: "mdi-check-circle-outline",
        bgColor: "#BCECC8",
        iconColor: "#29993E",
      },
      error: {
        icon: "mdi-information-outline",
        bgColor: "rgba(239, 197, 202, 1)",
        iconColor: "#B00020",
      },
      info: {
        icon: "mdi-information-outline",
        bgColor: "#f7d36f",
        iconColor: "rgba(0, 0, 0, 0.6)",
      },
      close: {
        icon: "mdi-close",
        bgColor: "inherit",
        iconColor: "rgba(0, 0, 0, 0.6)",
      },
    },
    // Types of the snackbar
    snackbarTypes: {
      success: "success",
      error: "error",
      info: "info",
    },

    icons: {
      error: "mdi-close",
    },
    iconColor: "#fffff",
  },
  loader: {
    color: "primary",
    secondary: "black",
    size: 70,
    small: 40,
    default: 45,
  },
  // Maropost policy link
  policyLink: "https://www.maropost.com/privacy-policy/",
  loginRedirectUrl: "https://staging.maropost.com",
  // Common error message
  commonErrorMessage: "Something went wrong.",
  // Common colors list
  commonColors: {
    error: "#B00020",
    errorLight: "#EFC5CA",
    success: "#29993E",
    successLight: "#BCECC8",
    info: "rgba(0, 0, 0, 0.87)",
    infoLight: "#F2F2F2",
  },
};

export default Object.freeze(appConfig);
