import { isEmpty, pick } from "@/utils";
import clone from "underscore/modules/clone";
import $appConfig from "@/config/app.config";
import CacheBuster from "@/services/CacheBuster";
import { APP_UPDATE_REMINDER_MILLIS } from "@/constants/app";

const { snackbar } = $appConfig;

/**
|--------------------------------------------------
| Module state
|--------------------------------------------------
*/
const state = {
  snackbar: {
    value: false,
    message: "",
    hide: false,
    type: snackbar.snackbarTypes.error,
    icon: snackbar.config.error.icon,
    previousError: "",
  },
  appMenu: false,
  routerHistory: [],
  appUpdates: {
    available: false,
    remindLater: false,
    showReminder: false,
    reminderTime: null,
    // App update banner will be shown after 15 minutes if remind me is marked as true
    remindLaterInterval: APP_UPDATE_REMINDER_MILLIS,
  },
  hideSnackbar: false,
};

const intialState = clone(state);

/**
 * Mutations constants
 */
const SET_SNACKBAR = "SET_SNACKBAR_ERROR";
const HIDE_SNACKBAR = "HIDE_SNACKBAR";
const SET_ROUTER_HISTORY = "SET_ROUTER_HISTORY";
const SET_HIDE_SNACKBAR = "SET_HIDE_SNACKBAR";
const REMOVE_ROUTER_HISTORY = "REMOVE_ROUTER_HISTORY";
const SET_APP_UPDATES = "SET_APP_UPDATES";
const RESET_UI_MODULE = "RESET_UI_MODULE";

/**
|--------------------------------------------------
| Mutations
|--------------------------------------------------
*/
const mutations = {
  [SET_SNACKBAR](state, data) {
    state.snackbar = { ...state.snackbar, ...data };
  },
  /**
   * Overides api error message visualisation in snackbar globally
   */
  [HIDE_SNACKBAR](state, hide) {
    state.snackbar.hide = hide;
  },
  [SET_ROUTER_HISTORY](state, data) {
    const history = pick(data, ["fullPath", "path", "name"]);
    if (!isEmpty(data)) {
      state.routerHistory.push(history);
    }
  },
  [REMOVE_ROUTER_HISTORY](state, data = []) {
    state.routerHistory = data;
  },
  [SET_APP_UPDATES](state, data) {
    state.appUpdates = {
      ...state.appUpdates,
      remindLaterInterval: APP_UPDATE_REMINDER_MILLIS,
      ...data,
    };
  },
  [SET_HIDE_SNACKBAR](state, data) {
    state.hideSnackbar = data;
  },
  [RESET_UI_MODULE](state) {
    Object.keys(state).forEach((key) => {
      state[key] = intialState[key];
    });
  },
  appMenu: (state, value) => {
    value = value !== true && value !== false ? !state.appMenu : value;
    state.appMenu = value;
  },
};

/**
|--------------------------------------------------
| Actions (Asynchronous)
|--------------------------------------------------
*/
const actions = {
  /**
   * Handles global snackbar
   */
  setSnackbar({ commit }, data) {
    commit(SET_SNACKBAR, data);
  },
  setAppUpdates({ commit }, data) {
    commit(SET_APP_UPDATES, data);
  },
  async downloadLatestVersion({ dispatch }) {
    const cacheBuster = new CacheBuster(false);
    cacheBuster.updateCache();

    const data = {
      available: false,
      remindLater: false,
      reminderTime: null,
      showReminder: false,
    };
    await dispatch("setAppUpdates", data);
  },
  setHideSnackbar({ commit }, data) {
    commit(SET_HIDE_SNACKBAR, data);
  },
};

/**
|--------------------------------------------------
| Getters to access state properties
|--------------------------------------------------
*/
const getters = {
  snackbar({ snackbar }) {
    return snackbar;
  },
  isSnackbarVisible({ snackbar }) {
    return snackbar?.value ?? false;
  },
  snackbarError({ snackbar }) {
    return snackbar?.message ?? "";
  },
  previousRoute({ routerHistory }) {
    return routerHistory[routerHistory.length - 1];
  },
  appUpdates({ appUpdates }) {
    return appUpdates;
  },
  hideSnackbar({ hideSnackbar }) {
    return hideSnackbar;
  },
  appMenu: (state) => state.appMenu,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
