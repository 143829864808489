
import { deepFreeze } from "@/utils";

/**
 * Keycloak user details property to be fetched from keycloak server
 * object
 */
export const KEYCLOAK_USER_DETAILS = Object.freeze([
  "email",
  "firstName",
  "lastName",
  "companyName",
  "phoneNumber",
  "emailVerified",
  "attributes"
]);

/**
 * Display breakpoints
 */
export const DISPLAY_BREAKPOINTS = {
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
};

/**
 * Common api respose codes
 */
export const RESPONSE_CODES = {
  success: 200,
  badRequest: 400,
  unAuthorised: 401,
  notFound: 404,
  internalServerError: 500,
  created: 201,
  noContent: 204,
  forbidden: 403,
  unProcessed: 422,
};

/**
 * USER_PROPS
 * User profile detail properties
 */
export const USER_PROPS = [
  "firstName",
  "lastName",
  "companyName",
  "phoneNumber",
];

/**
 * MAROPOST_LOCAL_DOMAINS
 * Local dev maropost domain
 */
export const MAROPOST_LOCAL_DOMAINS = {
  marketing: "http://app.maropost.local:8080",
  commerce: "http://localhost:8080",
};

/**
 * EMAIL_ACTION_CODES
 * Email action codes
 */
export const EMAIL_ACTION_CODES = {
  verifyEmail: "verifyEmail",
  recoverEmail: "recoverEmail",
  resetPassword: "resetPassword",
  revertSecondFactorAddition: "revertSecondFactorAddition",
};

/**
 * APP_UPDATE_REMINDER_MILLIS
 * Shows app update banner after these millis
 */
export const APP_UPDATE_REMINDER_MILLIS = 120000;

/**
 * DEFAULT_PHONE_COUNTRY_CODE
 * Default country code of the Phone number input field
 */
export const DEFAULT_PHONE_COUNTRY_CODE = "us";

/**
 * App enviroments enum (Immutable)
 */
export const ENVS = deepFreeze({
  development: "development",
  test: "test",
  production: "production",
  staging: "staging",
});


/**
 * Global debounce millis for debounced functions
 */
export const DEBOUNCE_MILLIS = 500;

/**
 * Default records number per page
 */
export const DEFAULT_PER_PAGE_RECORDS = 10;

/**
 * Marketing environment based clusters link
 */
export const MARKETING_CLUSTERS_LINK = {
  staging: "https://staging.maropost.com",
  uat: "https://uat.maropost.com",
  sandbox: "https://sandbox.maropost.com",
  production: "https://app.maropost.com",
};

/**
 * Error messages
 */
export const ERROR_MESSAGES = Object.freeze({
  userDoesNotExists: "User doesn’t exist on {{domain_name}}",
  default: "Something went wrong",
  defaultTryLater: "Something went wrong, Please try again later",
});
