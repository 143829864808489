import { keycloakObj} from "@/utils";
import { $accounts } from "./apis/http/http";
import { $identity } from "@/services/apis/";

/**
 * Fetches user profile details of logged in user
 */
export const getUserProfile = async () => {
  const { baseURL } = keycloakObj()
  return $identity.get("account", { baseURL, withCredentials: true });
};


export const userAccounts = (params = {}) => {
  return $accounts.get("accounts.json", { params });
};

/**
 * Updates a user profile record in Keycloak
 * @param {Object} payload - User profile data to update
 * @returns {Promise<Object>} API response
 */
export const updateUserProfile = (payload) => {
  const { baseURL } = keycloakObj()
  return $identity.post("account", payload, { baseURL });
};
