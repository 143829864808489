<template>
  <v-app-bar
    id="maropost-app"
    app
    clipped-right
    clipped-left
    color="header"
    tag="header"
    light
  >
    <!-- Future code -->
    <!-- <v-app-bar-nav-icon
      color="white"
      @click.stop="$emit('toggle-drawer')"
      slot="default"
    >
      <v-icon color="secondary"> mdi-menu </v-icon>
    </v-app-bar-nav-icon> -->

    <v-app-bar-nav-icon  @click="toggleAppMenu" />
    <v-toolbar-items class="w-100 pl-2">
      <v-menu offset-y :value="isProfileMenuOpen" @input="isProfileMenuOpen = !isProfileMenuOpen">
      <template v-slot:activator="{ on }">
        <v-btn
            v-on="on"    
            text
            id="top-left__dropdown"
            class=" pr-3"
            :class="headerClass"
            v-track="'maropost-profile-button'"
          >
            <v-icon
              medium
              class="pa-1 mr-2 white elevation-1 rounded-circle d-inline-block"
              color="#03B6FC"
              v-text="'mdi-account-circle-outline'"
            />

            <span
              class="
                text--primary
                d-none d-sm-flex
                subtitle-2
                secondary--font
                font-weight-large
                text-capitalize
              "
            >
             Maropost Profile
            </span>
            <v-icon color="dark-grey">
              {{ profileMenuIcon }}
            </v-icon>
          </v-btn>
        
      </template>
      <v-list>
        <v-list-item
              link
              dense
              @click.prevent="openMarketing"
              v-track="'marketing-cloud-btn'"
              class="px-5 text-black--80 cluster--link"
            >
              <v-list-item-icon class="mr-2">
                <img height="25" :src="$images.marketingCloudIcon" />
              </v-list-item-icon>
              <v-list-item-content
                class="pl-3 text-capitalize secondary--font caption"
              >
                Maropost Cloud
              </v-list-item-content>
              </v-list-item>
              <!-- Hide clusers option for a new signup user -->
              <template v-for="{ link, element_id } in clusters">
              <v-list-item
                link
                dense
                :href="link"
                :key="link"
                @click.prevent="openCluster(link)"
                v-track="element_id"
                class="px-5 text-black--80 cluster--link"
              >
                <v-list-item-icon class="mr-2">
                  <v-img
                    width="17px"
                    alt="Maropost Icon"
                    :src="$images.maropostIcon"
                  />
                </v-list-item-icon>
                <v-list-item-content class="pl-3 secondary--font caption">
                  {{ getDomainName(link) }}
                </v-list-item-content>
              </v-list-item>
            </template>
        </v-list>
        </v-menu>
          <v-spacer />
      <v-menu offset-y :value="isMenuOpen" @input="isMenuOpen = !isMenuOpen">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            text
            id="top-right__dropdown"
            class="pl-4 pr-3"
            :class="headerClass"
          >
            <v-icon
              medium
              class="pr-1"
              color="dark-grey"
              v-text="'mdi-account-circle'"
            />

            <span
              class="
                text--primary
                d-none d-sm-flex
                subtitle-2
                secondary--font
                font-weight-large
                text-capitalize
              "
            >
              {{ username }}
            </span>
            <v-icon color="dark-grey">
              {{ menuIcon }}
            </v-icon>
          </v-btn>
        </template>
        <v-list class="w-100 py-1" dense>
          <v-list-item-group color>
            <v-list-item
              link
              dense
              @click="logoutUser"
              v-track="'logout-user-btn'"
              class="px-5 text-black--80"
              style="min-height: 36px !important"
            >
              <v-list-item-icon class="mr-2">
                <v-icon v-text="logoutAction.icon" />
              </v-list-item-icon>
              <v-list-item-content
                class="pl-3 text-capitalize secondary--font caption"
              >
                {{ logoutAction.name }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import {
  capitalize,
  openLinkInWindow,
  toMarketingCluster,
} from "@/utils";
import { mapMutations, mapGetters } from "vuex";

/**
 * App header for authorised users
 */
export default {
  name: "AppHeader",
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      isMenuOpen: false,
      isProfileMenuOpen: false,
      clusters: [],
      logoutAction: {
        name: "Logout",
        icon: "mdi-logout",
        to: "logout",
      },
    };
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      userProfile: "auth/userProfile",
    }),
    /**
     * User name to be displayed at the header
     * @type {String}
     */
    username() {
      const { firstName, lastName } = this.userProfile ?? {};
      let name = `${firstName ?? ""} ${lastName ?? ""}`;

      if (!name) name = capitalize(this.userProfile?.email);
      return name;
    },
    /**
     * Header class for the name to be show to the user
     * @type {String}
     */
    headerClass() {
      const isDisplayNameExists = this.username === this.userProfile?.email;
      return !isDisplayNameExists ? "text-tranform--none" : "text-capitalize";
    },
    /**
     * Dropdown menu icon on the basis of menu is opened or not
     */
    menuIcon() {
      return this.isMenuOpen ? "mdi-menu-up" : "mdi-menu-down";
    },
    profileMenuIcon() {
      return this.isProfileMenuOpen ? "mdi-menu-up" : "mdi-menu-down";
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapMutations({
      onLogout: "auth/LOGOUT_USER",
    }),
    async logoutUser() {
      this.$keycloak.logout('get');
      await this.onLogout();
    },
    openMarketing() {
      const link = toMarketingCluster(false);
      openLinkInWindow(link);
    },
    /**
     * Replaces http protocol from the domain name
     */
    getDomainName(name) {
      return name.replace("https://", "");
    },
    /**
     * Computes the link to cluster with token and jti
     */
    openCluster(link) {
      openLinkInWindow(link);
    },
    toggleAppMenu() {
      this.$store.commit("ui/appMenu");
    },
  },
};
</script>

<style lang="scss" scoped>
#maropost-logo {
  z-index: 20 !important;
}
#maropost-app {
  z-index: 100 !important;
  background: linear-gradient(180deg,#03b6fc 4px,#fff 0);
}

.cluster--link {
  min-height: 36px !important;
}
</style>
