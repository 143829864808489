import Vue from "vue";

/**
 * Request interceptor to intercept all request sent to identity server
 * @returns {Request}
 */
export const identityRequestInterceptor = async (config) => {
  try {
      const keycloak = Vue.prototype.$keycloak;
      const refreshBuffer = parseInt(process.env.VUE_APP_KEYCLOAK_REFRESH_TOKEN, 10) || 30;
      try {
        await keycloak.updateToken(refreshBuffer);
        config.headers["Authorization"] = `Bearer ${keycloak.token}`;
      } catch (error) {
        console.error('Failed to refresh token:', error);
        keycloak.login({
          redirectUri: `${process.env.VUE_APP_HOME_URI}`,
        });
      }
    return config;
  } catch (error) {
    console.warn(error);
    return config;
  }
};
