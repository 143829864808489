import Vue from "vue";
import store from "@/store";
import { isEmpty, sanitaiseInvalidRoute } from "@/utils";
import CacheBuster from "@/services/CacheBuster";
const cacheBuster = new CacheBuster(true);

/**
 * Auth guard router middleware
 * @description before each route, check if user needs auth
 * @param {Object} to - the route to change to
 * @param {Object} from - the previous route
 * @param {Object} next - callback
 */
export const auth = async (to, from, next) => {
  // Initialize app version checker worker for cache busting
  await cacheBuster.setAppVersionCheckerWorker();

  // Retrieve store getters and Keycloak instance
  const { getters } = store;
  const keycloak = Vue.prototype.$keycloak;

  // Determine user login status and if the route requires authentication
  const isUserLoggedIn = keycloak.authenticated || getters["auth/isLoggedIn"];
  const isRouteAuthorized = to.matched.some(({ meta }) => meta.requiresAuth);

  // Sanitize invalid routes (custom function)
  sanitaiseInvalidRoute();

  // Redirect to the "profile" route if the target route is invalid
  if (isEmpty(to?.matched)) {
    next({ name: "profile" });
    return; // Exit early to avoid further checks
  }

  // If the user is not logged in and the route requires authentication, redirect to login
  if (!isUserLoggedIn && isRouteAuthorized) {
    keycloak.login({
      redirectUri: `${process.env.VUE_APP_HOME_URI}`,
    });
    return; // Exit early to prevent continuing execution
  }

  // If the user is logged in, proceed to the target route
  if (isUserLoggedIn) {
    next();
  }
};

