import router from "@/router";
import { pick } from "@/utils";

import { KEYCLOAK_USER_DETAILS } from "@/constants/app.js";
import GainsightMixin from "@/mixins/Gainsight.mixin";

/**
|--------------------------------------------------
| Module state
|--------------------------------------------------
*/
const state = {
  currentUser: null,
  isLoggedIn: false,
  userProfile: null,
};

/**
|--------------------------------------------------
| Mutations constants
|--------------------------------------------------
*/
const LOGOUT_USER = "LOGOUT_USER";
const SET_CURRENT_USER = "SET_CURRENT_USER";
const UPDATE_CURRENT_USER_DETAILS = "UPDATE_CURRENT_USER_DETAILS";
const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
const SET_USER_PROFILE_DETAILS = "SET_USER_PROFILE_DETAILS";

/**
|--------------------------------------------------
| Mutations
|--------------------------------------------------
*/
const mutations = {
  [SET_CURRENT_USER](state, data) {
    state.currentUser = data;
    state.isLoggedIn = true;
  },
  [LOGOUT_USER](state) {
    state.currentUser = null;
    state.isLoggedIn = false;
    state.userProfile = null;
  },
  [UPDATE_USER_DETAILS](state, data) {
    state.currentUser = { ...state.currentUser, ...data };
  },
  [UPDATE_CURRENT_USER_DETAILS](state, data) {
    state.currentUser = { ...state.currentUser, ...data };
  },
  [SET_USER_PROFILE_DETAILS](state, data) {
    state.userProfile = { ...state.userProfile, ...data };
  },
};

/**
|--------------------------------------------------
| Actions (Asynchronous)
|--------------------------------------------------
*/
const actions = {
  /**
   * Logs outs a user from the application
   */
  async logoutUser({ commit }) {
    const { resetSession } = GainsightMixin.methods;
    try {
      await commit("ui/RESET_UI_MODULE", [], { root: true });
    } catch (error) {
      Promise.reject(error);
    } finally {
      await commit(LOGOUT_USER);
      await resetSession();
      localStorage.removeItem("mp-id");
    }
  },
  /**
   * Sets current user details and it's identity details in the store
   */
  async setCurrentUserDetails(
    { commit, dispatch, state: { isLoggedIn } },
    { ...data }
  ) {
    try {
      const currentUser = pick(data, KEYCLOAK_USER_DETAILS);

      await commit(SET_CURRENT_USER, currentUser);

      await commit("ui/SET_ROUTER_HISTORY", router.app?.$route, { root: true });

    } catch (error) {
      isLoggedIn && dispatch("logoutUser");
      return Promise.reject(error);
    }
  },
  async setKeycloakCurrentUserDetails(
    { commit, dispatch, state: { isLoggedIn }, getters },
    { ...data }
  ) {
    try {
      
      const currentUser = pick(data, KEYCLOAK_USER_DETAILS);

      await commit(SET_CURRENT_USER, currentUser);
      await commit(SET_USER_PROFILE_DETAILS, currentUser);
      await dispatch("initialiseGainsightUser", getters.userProfile);
      await commit("ui/SET_ROUTER_HISTORY", router.app?.$route, { root: true });

    } catch (error) {
      isLoggedIn && dispatch("logoutUser");
      return Promise.reject(error);
    }
  },
  async initialiseGainsightUser() {
    const [ userProfile] = arguments;
    const { methods } = GainsightMixin;
    await methods.initialiseUser(userProfile);
  },
};

/**
|--------------------------------------------------
| Getters to access state properties
|--------------------------------------------------
*/
const getters = {
  currentUser({ currentUser }) {
    return currentUser;
  },
  isEmailVerified({ currentUser }) {
    return currentUser?.emailVerified ?? false;
  },
  /**
  |--------------------------------------------------
  | Update the check over here
  |--------------------------------------------------
  */
 
  isLoggedIn({ isLoggedIn }) {
    return isLoggedIn;
  },
 
  userProfile({ userProfile }) {
    return { ...userProfile };
  },
  currentUserEmail({ currentUser }) {
    return currentUser?.email ?? "";
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
